import Grid from "@egjs/grid";
import {
  Box,
  Button,
  Container,
  Input,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ReCaptchaV2 from "react-google-recaptcha";
import {
  Buttons,
  GeneralHeading,
  MainContainer,
  ParagraphsBlue,
} from "../../atoms";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import emailjs from "emailjs-com";
import { ArrowForwardSharp } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5% auto",
    margin: "0% auto",
    // border:'2px solid red',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
      margin: "10% auto",
    },
  },
  leftBox: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightBox: {
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "8%",
    },
  },
  input: {
    margin: "4% 0%",
    width: "100%",
    color: "white",
    "& .Mui-focused": {
      color: "white",
      opacity: ".6",
    },
    "& .Mui-FormLabel-root": {
      color: "white",
    },
    "& .MuiInputBase-root": {
      color: "white",
    },
    "& .MuiInput-underline::before": {
      opacity: "1",
      // borderBottom: "1px solid white",
    },
    "& .MuiInput-underline::after": {
      opacity: "1",
      border: "1px solid white",
    },
    "& .MuiInput-underline:hover:before": {
      opacity: "1",
      // borderBottom: "1px solid white",
    },
  },
  cssLabel: {
    color: "white",
  },
  cssError: {
    color: "red",
  },
  cssFocused: {
    color: "white",
  },
  btnBox: {
    width: "100%",
    display: "flex",
    justifyContent: "right",
    marginTop: "10%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: "0%",
    },
  },
  btn: {
    height: "100%",
    background: "#182AC3",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    zIndex: "1",
    transition: "all .3s ease-in-out",
    transition: "background .5s ease-in-out",
    borderRadius: "4px",
    overflow: "hidden",
    padding: "1% 3%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&:disabled": {
      opacity: ".5",
    },
    "&::before": {
      content: "''",
      position: "absolute",
      borderRadius: "4px",
      right: "0%",
      top: "0%",
      width: "100%",
      height: "2px",
      transition: "all .5s ease-in-out",
      background: "#fff",
      zIndex: "-999",
    },
    "&::after": {
      content: "''",
      position: "absolute",
      borderRadius: "4px",
      right: "0%",
      top: "0%",
      width: "2px",
      height: "100%",
      transition: "all .5s ease-in-out",
      background: "#fff",
      zIndex: "-999",
    },
    "&:hover": {
      background: "#fff",
      "& $btnTitle": {
        color: "#182AC3",
      },
      "&::before": {
        width: "0%",
      },
      "&::after": {
        height: "0%",
      },
      "& $mySpan": {
        "&::before": {
          width: "0%",
        },
        "&::after": {
          height: "0%",
        },
      },
    },
  },
  btnTitle: {
    color: "#fff",
    fontFamily: "Access",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    verticalAlign: "middle",
    transition: "transform .5s ease-in-out",
  },
  arrow: {
    width: "1.2rem",
    marginLeft: "4%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      maxWidth: "100%",
      height: "100%",
      alignSelf: "center",
    },
    [theme.breakpoints.down("sm")]: {
      width: "1rem",
    },
  },
  arrow1: {
    marginLeft: "5px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5px",
      fontSize: "1.2rem",
    },
  },
  mySpan: {
    "&::before": {
      content: "''",
      position: "absolute",
      borderRadius: "4px",
      left: "0%",
      bottom: "0%",
      width: "100%",
      height: "2px",
      transition: "all .5s ease-in-out",
      background: "#fff",
      zIndex: "-99",
    },
    "&::after": {
      content: "''",
      position: "absolute",
      borderRadius: "4px",
      left: "0%",
      bottom: "0%",
      width: "2px",
      height: "100%",
      transition: "all .5s ease-in-out",
      background: "#fff",
      zIndex: "-99",
    },
  },
  iconBox: {
    display: "flex",
    alignItems: "flex-end",
  },
  address: {
    marginTop: "2%",
  },
  textBtm: {
    width: "50%",
    margin: "0% auto",
    padding: "8% 0%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  captcha: {
    marginTop: "5%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.70)",
      webkitTransform: "scale(0.70)",
      transformOrigin: "0 0",
      webkitTransformOrigin: "0 0",
    },
  },
  loaderWrapper: {
    position: "fixed",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "9999",
    background: "rgba(0,0,0,0.5)",
  },
  loader: {
    stroke: "white",
    width: "100px",
  },
}));

export function Letstalk() {
  const classes = useStyles();
  const history = useHistory();
  const [isVerified, setIsVerified] = useState(false);
  const [form, setForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialFormState = {
    name: "",
    email: "",
    message: "",
    phone: ""
  };

  const [contactus, setContactus] = useState(initialFormState);


  const handleChange = (e) => {
    setContactus({
      ...contactus,
      [e.target.name]: e.target.value,
    });
  }

// const validate = (e) => {
  //   let tempErrors = { ...errors };
  //   if (contactus.name) {
  //     tempErrors.name = /^[a-zA-Z]{2}(?:[a-zA-Z\s]{0,28}[a-zA-Z])?$/.test(contactus.name)
  //       ? null
  //       : "Please enter valid name.";
  //   }
  //   if (contactus.email) {
  //     tempErrors.email = /^[^\s]{2}[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(contactus.email)
  //       ? null
  //       : "Please enter valid email.";
  //   }
  //   if (contactus.phone) {
  //     tempErrors.phone = /^\d{2}(?:\s?\d{6,14})?$/.test(contactus.phone)
  //       ? null
  //       : "Please enter valid phone number.";
  //   }
  //   if (contactus.message) {
  //     const msgLen = contactus.message.length;
  //     tempErrors.message = msgLen != 0 ? null : "Please leave a message for us.";
  //   }

  //   for (let i = 0; i <= Object.keys(errors).length; i++) {
  //     if (tempErrors[i] === null || tempErrors === "") {
  //       delete tempErrors[i]
  //       console.log(tempErrors)
  //     }
  //   }

  //   setErrors(...tempErrors);

  //   return Object.values(tempErrors).length !== 0
  // };

  const validate = (values) => {
    const errorsTmp = {}
    if (values.name === '') {
      errorsTmp.name = 'Please Enter Your Name'
    }
    if (!values.email) {
      errorsTmp.email = 'Please Enter Your Email'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errorsTmp.email = 'Invalid Email Address'
    }

    // if (values.phone === '') {
    //   errorsTmp.phone = 'Please Enter Your Phone Number'
    // }

    // if ((values.phone + '').length !== 10) {
    //   errorsTmp.phone = 'Please Enter Valid Phone Number'
    // }


    if (values.phone === '') {
      errorsTmp.phone = 'Please Enter Your Phone Number';
    }
    // Check if phone number matches the expected format (+<countryCode><10 digits>)
    else if (!/^\+\d{1,3} \d{10}$/.test(values.phone)) {
      errorsTmp.phone = 'Please Enter a Valid Phone Number with Country Code';
    } 

    if (values.message === '') {
      errorsTmp.message = 'Please Leave us a Message'
    }
    setErrors(errorsTmp)
    return Object.keys(errorsTmp).length === 0
  }


  //emailjs
  const forms = useRef();

  const serviceID = process.env.REACT_APP_SERVICE_ID
  const templateID = process.env.REACT_APP_TEMPLATE_ID
  const publickey = process.env.REACT_APP_PUBLIC_KEY


  emailjs.init("tXAYsELvGixFRN_3-");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validate(contactus)) {
      setIsLoading(true);
      const emjs = await emailjs.sendForm(
        serviceID,
        templateID,
        forms.current,
        publickey
      )
        .then(
          (result) => {
            console.log(result.text);
            setIsLoading(false);
            swal({
              title: "Message successfully sent!",
              text: "Our team will get back to you shortly!",
              icon: "success",
              button: "Okay",
            }).then(() => {
              setForm((currentForm) => {
                return { ...currentForm, token: null };
              });
            });
          },
          (error) => {
            console.log(error.text);
            setIsLoading(false);
            swal({
              title: "Something went wrong!",
              text: "Thank you for connecting with us",
              icon: "",
            }).then(() => {
              setForm((currentForm) => {
                return { ...currentForm, token: null };
              });
            });
          }
        );

      const captcha = captchaRef.current;
      handleReset()
      captcha.reset();
      setIsVerified(false);
      setContactus({});

    }
  };

  let captchaRef = useRef();

  const handleToken = (token) => {
    setForm((currentForm) => {
      return { ...currentForm, token };
    });
    setIsVerified(true);
  };

  const handleExpire = () => {
    setForm((currentForm) => {
      return { ...currentForm, token: null };
    });
  };

  const handleReset = () => {
    setContactus({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };


  useEffect(() => { }, [isLoading]);

  const scrollableRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  useEffect(() => {    
    if(screenWidth >= 960){
      // Set the maximum scroll height when the component mounts
      if (scrollableRef.current) {
        const scrollableHeight = scrollableRef.current.offsetHeight;
        const stickyHeight = 785; 
        setMaxScroll(scrollableHeight - stickyHeight);
      }
  
      const handleScroll = () => {
        // Update scroll position, but cap it at maxScroll      
        setScrollY(Math.min(window.scrollY, maxScroll));
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }else{
      setScrollY(0)
    }
  }, [maxScroll, screenWidth]);

  return (
    <>
      <MainContainer>
        <Box className={classes.mainBox} style={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '20px',
          padding: '20px',
          
        }}>
         

          <Box className={classes.leftBox} ref={scrollableRef}
        style={{
          flex: 1,                    
        }}>
            <Box style={{ marginTop: "0%" }}>
            <ParagraphsBlue
              title={<span style={{ color: 'black' }}>Industry-First Fastest USB 20Gbps Device IP Core Launch By SLS</span>}
              // subtitle1= "We'll be glad to help you with your queries.

              // Kindly fill-in this enquiry form and we will get back to you within 24 hours. Continuation of the same examples...

              // The principal basis for restricting cell phone use in the classroom is that phones can act as a distraction. Not only do students and teachers become diverted, but this has an analogous impact on someone glancing at their device during a movie screening - even if it's silent, the illuminated display will still divert one's focus away from what matters most!
              
              // When debating mobile phone regulations in the classroom, safety is typically brought to the forefront. Emergencies may happen at any time and students should have their phones with them for peace of mind. Parents' needs come into play too; if a student has children, they could need access to call someone during medical emergencies. Furthermore, if an individual is on standby for work-related matters then having a cell phone accessible would be useful as well - there are endless plausible scenarios that make it difficult not to provide exceptions from these rules!
              
              // To ensure all students have an opportunity to learn without distraction, the optimal choice is to establish and enforce mobile phone usage rules. With these guidelines in place, pupils should be able to carry their devices with them as long as they remain on silent during class hours. This way, phones are easily accessible for any necessary use but don't interfere with anyone's learning experience. Vibrate settings can be allowed if the instructor feels comfortable with it, as the buzz may not be heard in a crowded classroom. In an emergency situation, students can quickly step out of class to answer their phones. This will create a more relaxed environment for both instructors and students alike.
              
              // Cell phone restrictions in the classroom should be enforced with clear and specific disciplinary measures for violations. For instance, if a student is caught using their cell phone during class, they can be excused from that day's classes. Professors should avoid seizing control of the device out of consideration for potential liability issues. It's safer to request students leave the classroom than take away their phones, as any damages incurred while in a professor's possession could result in liability for repairs on behalf of the school or instructor"
              // redline
            />

              
              <Box className={classes.address}>
                <h6 style={{color:"black"}}> System Level Solutions Inc. (SLS) is pioneering high-speed data transfer with the world's first USB 20Gbps Device IP Core for Altera ® FPGAs. This groundbreaking solution offers unprecedented performance, empowering developers to create cutting-edge applications.</h6>
                
              </Box>
              <Box className={classes.address}>
                <h6 style={{color:"black"}}> Rigorously tested for compatibility and scalability, our USB 20 Gbps IP Core ensures seamless integration with existing systems and future-proofs your Altera® FPGA designs. Our USB 20 Gbps IP Core empowers businesses to innovate rapidly and effectively by reducing design complexity and ensuring seamless integration. With its exceptional performance and versatility, this solution is poised to become the go-to choice for developers seeking to leverage the power of USB 20 Gbps technology.</h6>
                
              </Box>
              <Box className={classes.address}>
                <h6 style={{color:"black",  fontSize:"1.5rem", fontWeight: "bold" }}> USB 20Gbps IP core enhanced performance and capabilities:</h6>
                
              </Box>
              
              <Box className={classes.address}>
                <h6 style={{color:"black"}}> Currently, the enhanced USB 20Gbps capability is available for Altera® FPGA. Our USB 20Gbps IP core significantly enhances Altera ® FPGA performance and capabilities. It facilitates rapid data movement between the Altera® FPGA and external devices, enabling real-time processing of large data streams. This increased system bandwidth supports parallel processing of multiple data streams, crucial for high-resolution video and audio processing, as well as high-speed data acquisition and control.</h6>
                
              </Box>
              <Box className={classes.address}>
                <h6 style={{color:"black",  }}> Its flexible design and customization capabilities allow for the creation of tailored Altera ® FPGA systems. It can be configured to meet specific performance and latency requirements, adapting to diverse application scenarios.</h6>
                
              </Box>
              <Box className={classes.address}>
                <h6 style={{color:"black"}}> The core offers low latency and high throughput, optimized pipeline architecture, parallel processing techniques, and design.</h6>
                
              </Box>
              <Box className={classes.address}>
                <h6 style={{color:"black"}}> By integrating a USB 20Gbps IP core, Altera® FPGA designers can develop highly efficient and powerful systems that meet the demands of modern applications, such as 5G wireless communications, high-performance computing, machine learning, and data centers.</h6>
                
              </Box>
              
              <Box className={classes.address}>
                <h6 style={{color:"black", fontSize:"1.5rem", fontWeight: "bold"}}> Here are some more optimizing features to look at:  </h6>
                
              </Box>
              <Box className={classes.addressContainer} style={{ backgroundColor: "lightgrey", padding: "20px", borderRadius: "8px", marginTop: "20px" }}>
  <Box className={classes.address}>
    <h6 style={{ color: "black" }}>
      <span style={{ fontWeight: "bold" }}>Cost Efficiency:</span> Our IP core’s built-in FPGA transceiver eliminates the need for external PHY chips, simplifying the integration process and reducing overall system cost.
    </h6>
  </Box>

  <Box className={classes.address}>
    <h6 style={{ color: "black" }}>
      <span style={{ fontWeight: "bold" }}>Unparalleled Speed:</span> Our IP core’s unparalleled speed of up to 20 Gbps empowers real-time applications, essential for sectors like consumer electronics, automotive, AI, and medical devices. For instance, medical devices can benefit from real-time data processing, enabling faster diagnosis and treatment.
    </h6>
  </Box>

  <Box className={classes.address}>
    <h6 style={{ color: "black" }}>
      <span style={{ fontWeight: "bold" }}>Seamless Integration:</span> Compatibility is validated through rigorous testing, ensuring that the IP core meets performance benchmarks and integrates well with other system components. The system is designed to scale, accommodating future upgrades or expansions in bandwidth and processing capabilities.
    </h6>
  </Box>

  <Box className={classes.address}>
    <h6 style={{ color: "black" }}>
      <span style={{ fontWeight: "bold" }}>Host Controller Adaptation:</span> Our USB 20 Gbps IP core operates as a device, accommodating the host's commands and ensuring seamless data transfer within the specified protocol constraints. This host controller adaptation is crucial for optimal performance and compatibility with various systems.
    </h6>
  </Box>

  <Box className={classes.address}>
    <h6 style={{ color: "black" }}>
      <span style={{ fontWeight: "bold" }}>Leveraging Native Transceiver Technology:</span> In a USB 20Gbps IP core, the native transceiver is crucial for achieving the required speed and reliability in data communication. This technology enables high-performance computing, networking, and high-definition video processing applications.
    </h6>
  </Box>

  <Box className={classes.address}>
    <h6 style={{ color: "black" }}>
      <span style={{ fontWeight: "bold" }}>Future-Proof Your Systems:</span> The IP core solution offers a path to upgrade legacy systems, improving data transfer speeds and facilitating future system enhancements.
    </h6>
  </Box>
</Box>

              <Box className={classes.address}>
                <h6 style={{color:"black"}}> Our CEO, Mr. Paresh Patel, expressed his excitement about this groundbreaking achievement: "I'm thrilled to announce the launch of our groundbreaking USB 20 Gbps solution on Altera ® FPGA. This innovative product is set to transform high-speed data transfer across various industries, including consumer electronics, automotive, medical, and more. Our team at SLS has successfully developed a groundbreaking solution that operates at 20 Gbps and this achievement is not just a number; it represents the industry's first USB 20Gbps IP Core, setting a new standard for performance and efficiency.  </h6>
                
              </Box>
              <Box className={classes.address}>
                <h6 style={{color:"black"}}> I invite all businesses, especially those looking to upgrade their legacy systems, to consider our solution. It not only boosts data transfer speeds but also simplifies the upgrade process.”  </h6>
                
              </Box>
              <Box className={classes.address}>
                <h6 style={{color:"black"}}> USB 20 Gbps IP core for Altera® FPGA is available for immediate use. We at SLS offer device controller adaptation services and other customized solutions to support high-speed data transfer adaptation. </h6>
                
              </Box>
              <Box className={classes.address}>
                <h6 style={{color:"black"}}> 
                <span style={{ fontWeight: "bold" }}>About SLS:</span>  System Level Solutions (SLS) is a company that has engineered technology, incubated solutions, and accelerated tech products and services for over 22 years. As a pioneer in semiconductor technology, we are dedicated to delivering innovative solutions that enhance performance and connectivity across various industries. Our commitment to excellence and customer satisfaction drives us to continuously push the boundaries of what is possible. The USB 20 Gbps IP Core exemplifies our dedication to providing cutting-edge solutions that meet the evolving needs of the market. </h6>
                
              </Box>



            </Box>
            {/* <Box style={{ marginTop: "10%" }}>
              <Box className={classes.iconBox}>
                <img src={"./images/contactus/Layer_x0020_1-1.svg"} />
                <h4 style={{ marginLeft: "5%" }}>USA</h4>
              </Box>
              <Box className={classes.address}>
                <h6> System Level Solutions Inc. </h6>
                <h6> 511 N. Washington Avenue, </h6>
                <h6> Marshall, Texas - 75670 </h6>
                <h6> Ph: 001-408-852-0067 </h6>
                <h6> Ph: 001-408-705-2339 (Rings in India)</h6>
              </Box>
            </Box> */}
            
          </Box>

          <Box className={classes.rightBox} style={{                      
          position: 'relative',
          marginTop: '50px',
          transform: `translateY(${scrollY}px)`,
          scrollBehavior: 'smooth'
        }}>
            <Box
              style={{
                backgroundColor: '#182AC3',
                padding: '20px',
                borderRadius: '8px',          
              }}
            >
            <ParagraphsBlue
            title={<span style={{ color: 'White' }}>Let's Talk</span>}
            subtitle1={<span style={{ color: 'White' }}>We'll be glad to help you with your queries. Kindly fill-in this enquiry form and we will get back to you within 24 hours.</span>}
              redline
            />
            <form
              ref={forms}
              onSubmit={(event) => handleSubmit(event)}
              style={{ backgroundColor: '#182AC3', padding: '20px', borderRadius: '8px', marginTop: '20px', }} // Added marginTop here
            >
            <TextField
            onChange={handleChange}
            value={contactus.name}
            helperText={errors.name}
            autoComplete="off"
            name="name"
            type="text"
            label="Name"
            placeholder="John Doe"
            className={classes.input}
            variant="standard"
            style={{
              color: 'black',
              fontWeight: 'bold',
              backgroundColor: '#f0f0f0',
              borderRadius: '4px',
            }}
            InputLabelProps={{
              style: { color: 'black', fontWeight: 'bold', marginLeft: '10px' },
            }}
            InputProps={{
              style: { color: 'black', fontWeight: 'bold', paddingLeft: '10px' }, // Added paddingLeft for placeholder
            }}
            FormHelperTextProps={{
              style: { color: 'black' },
            }}
          />

          <TextField
            onChange={handleChange}
            value={contactus.email}
            helperText={errors.email}
            autoComplete="off"
            label="Email"
            name="email"
            placeholder="example@email.com"
            className={classes.input}
            variant="standard"
            style={{
              color: 'black',
              fontWeight: 'bold',
              backgroundColor: '#f0f0f0',
              borderRadius: '4px',
            }}
            InputLabelProps={{
              style: { color: 'black', fontWeight: 'bold', marginLeft: '10px' },
            }}
            InputProps={{
              style: { color: 'black', fontWeight: 'bold', paddingLeft: '10px' }, // Added paddingLeft for placeholder
            }}
            FormHelperTextProps={{
              style: { color: 'black' },
            }}
          />

          <TextField
            onChange={handleChange}
            value={contactus.phone}
            helperText={errors.phone}
            autoComplete="off"
            label="Phone"
            name="phone"
            type="tel"
            placeholder="+91 12345 67890"
            className={classes.input}
            variant="standard"
            style={{
              color: 'black',
              fontWeight: 'bold',
              backgroundColor: '#f0f0f0',
              borderRadius: '4px',
            }}
            InputLabelProps={{
              style: { color: 'black', fontWeight: 'bold', marginLeft: '10px' },
            }}
            InputProps={{
              style: { color: 'black', fontWeight: 'bold', paddingLeft: '10px' }, // Added paddingLeft for placeholder
            }}
            FormHelperTextProps={{
              style: { color: 'black' },
            }}
          />

          <TextField
            onChange={handleChange}
            value={contactus.message}
            helperText={errors.message}
            autoComplete="off"
            multiline
            maxRows={5}
            label="Message"
            name="message"
            placeholder="Message"
            className={classes.input}
            variant="standard"
            style={{
              color: 'black',
              fontWeight: 'bold',
              backgroundColor: '#f0f0f0',
              borderRadius: '4px',
            }}
            InputLabelProps={{
              style: { color: 'black', fontWeight: 'bold', marginLeft: '10px' },
            }}
            InputProps={{
              style: { color: 'black', fontWeight: 'bold', paddingLeft: '10px' }, // Added paddingLeft for placeholder
            }}
            FormHelperTextProps={{
              style: { color: 'black' },
            }}
          />



              {/* Captcha Here */}
              <Box className={classes.captcha}>
                <ReCaptchaV2
                  sitekey="6Le9EQMiAAAAAO1asvkBsggwHhiBp_bMmpIifGzZ" // Site Key for https://www.slscorp.com/ not working for the localhost
                  ref={captchaRef}
                  onChange={handleToken}
                  onExpire={handleExpire}
                />
              </Box>

              <Box className={classes.btnBox} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Button
                  type="submit"
                  className={classes.btn}
                  disabled={!isVerified}
                  style={{
                    backgroundColor: 'white', // Set to a shade of ash
                    color: 'black', // Set text color to black
                    width: '150px', // Set width for consistency
                    padding: '10px', // Padding for better click area'
                    textAlign: 'center', // Center text
                    fontWeight: 'bold', // Bold text
                  }}
                >
                  Send Message
                </Button>
              </Box>
            </form>
            </Box>
          </Box>

        </Box>


            <div
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "#182AC3", // Blue color
                margin: "70px 0", // Spacing around the separator
              }}
            />

        <Box className={classes.textBtm} style={{
               padding :"0px",
               margin : "0px"
              }} >
          {/* <h4>
            For more information about products and services, please contact us
            at info@slscorp.com
          </h4> */}
        </Box>

        
      </MainContainer>

      {!isLoading ? (
        ""
      ) : (
        <div className={classes.loaderWrapper}>
          <svg
            viewBox="0 0 100 100"
            className={classes.loader}
            preserveAspectRatio="xMidYMid"
            id="Icon_ionic-ios-arrow-round-forward"
          >
            <circle
              id="loader__svg"
              cx="50"
              cy="50"
              fill="none"
              strokeWidth="10"
              r="35"
              strokeDasharray="164.93361431346415 56.97787143782138"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      )}      
    </>
  );
}
