import React from 'react'
import { Navbar, Footer } from '..'
import { GridBlue } from '../../atoms'
import { Ubanner, Overview, Quality, QualityReverse, Visit, Visit1 } from '../../molecules'
import { Industries } from './Industries'
import { Offerings } from './Offerings'
import { OurexpertiseBlue } from './OurexpertiseBlue'
import { Products } from './Products'

export function IPcoreTemplate() {
    return (
        <div>
            <Ubanner
                Background="./images/luo-lei-Xa4pTwafe5U-unsplash-ipcore.png"
                maintext="IP CORE/ASIC/FPGA/SOC DESIGN SERVICES"
                subtext="World leaders in FPGA SoC design services"
            />
            <GridBlue>
                <Navbar />

                <Overview
                    path="Services / IP CORE/ASIC/FPGA/SOC DESIGN SERVICES"
                    sub1="SLS has been spearheading the design and delivery of world-class ASIC, FPGA and IP Core services for more than two decades. We hold an extensive portfolio of IP Cores with exceptional time-to-market and ever-growing functionalities, serving 150+ clients globally. Our ready-to-deploy FPGA solutions are designed to meet the dynamic needs of the market and match pace with the changing technology status quo, always aiming to deliver excellence."
                />
            </GridBlue>

            
            <Offerings />      

            <Products />

            <OurexpertiseBlue />

            <Industries />

            <Visit1
                goto="Explore more about our FPGA Products and Services!"
                title="Visit Website"
                href={"https://core.slscorp.com/services/fpgasoc-design-services.html"}
            />
            
            <Footer />
        </div>
    )
}
