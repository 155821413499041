import React from 'react'
import { ScrollTop } from '../../molecules'
import { TwentyGBPSTemplate } from '../../templates'


// const index = () => {
//   return (
//     <div>
//       <h1>
//         pr pages
//       </h1>
//     </div>
//   )
// }

// export default index

export function TwentyGBPS() {
  return (
    <div>
      <ScrollTop />
      <TwentyGBPSTemplate />
        
    </div>
  )
}
