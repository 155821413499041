import React from "react";
import { Box, makeStyles, Button } from "@material-ui/core";
import Fade from "react-reveal/Fade";
import Roll from "react-reveal/Roll";
import Jello from "react-reveal/Jello";
import $ from "jquery";
import "./Sbanner.css";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    height: "100vh",
    display: "flex",
    background: "black",
    zIndex: "0",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
    position: "relative",
    // transform: ' translate3d(0px, 0px, 0px)',
    // transition: 'all 700ms ease',
  },
  backImg: {
    position: "absolute",
    maxWidth: "100%",
    height: "100%",
    zIndex: "-1",
    "& img": {
      maxWidth: "100%",
      height: "100%",
    },
  },
  textBox: {
    height: "100vh",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    top: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  mainText: {
    lineHeight: ".8",
    zIndex: "1",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      // fontSize: '4rem'
    },
    [theme.breakpoints.down("xs")]: {
      // fontSize: '3rem'
    },
  },
  redLine: {
    background: "#CA0017",
    height: "2px",
    width: "25%",
    margin: "1% 0%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  subText: {
    width: "40%",
    fontFamily: "Access",
    // font: 'normal normal normal 1rem Access',
    color: "white",
    // textAlign: "center",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      // fontSize: '1rem'
    },
  },
  scrollBox: {
    position: "absolute",
    bottom: "5%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {},
  },
  mouseScroll: {
    transition: "all 100ms ease-in",
    "&:hover": {
      transform: "scale(1.1)",
      cursor: "pointer",
    },

    "& img": {
      maxWidth: "100%",
    },
  },
  mouseScrollTxt: {
    color: "white",
    transition: "all 100ms ease-in",
    marginTop: "3%",
    "&:hover": {
      transform: "scale(1.15)",
      cursor: "pointer",
    },
  },
  btn: {
    // height: '100%',
    width: '65%',
    background: '#D71921',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    zIndex: '9',
    transition: 'all .3s ease-in-out',
    transition: 'background .5s ease-in-out',
    borderRadius: '4px',
    overflow: 'hidden',

    marginTop: '50px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    "&::before": {
      content: "''",
      position: 'absolute',
      borderRadius: '4px',
      right: '0%',
      top: '0%',
      width: '0',
      height: '2px',
      transition: 'all .5s ease-in-out',
      background: '#D71921',
      zIndex: '-999'
    },
    "&::after": {
      content: "''",
      position: 'absolute',
      borderRadius: '4px',
      right: '0%',
      top: '0%',
      width: '2px',
      height: '0%',
      transition: 'all .5s ease-in-out',
      background: '#D71921',
      zIndex: '-999'
    },
    '&:hover': {
      background: '#D71921',
      "& $btnTitle": {
        color: '#fff',
      },
      "&::before": {
        width: '100%',
      },
      "&::after": {
        height: '100%',
      },
      "& $mySpan": {
        "&::before": {
          width: '100%',
        },
        "&::after": {
          height: '100%',
        },
      },
    },
  },
  mySpan: {
    "&::before": {
      content: "''",
      position: 'absolute',
      borderRadius: '4px',
      left: '0%',
      bottom: '0%',
      width: '0',
      height: '2px',
      transition: 'all .5s ease-in-out',
      background: '#D71921',
      zIndex: '-999'
    },
    "&::after": {
      content: "''",
      position: 'absolute',
      borderRadius: '4px',
      left: '0%',
      bottom: '0%',
      width: '2px',
      height: '0%',
      transition: 'all .5s ease-in-out',
      background: '#`D71921`',
      zIndex: '-999'
    }
  },
  btnTitle: {
    color: '#fff',
    fontFamily: 'Access',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    verticalAlign: 'middle',
    transition: 'transform .5s ease-in-out',
    whiteSpace: 'no-wrap'
  },
}));

export function Sbanner({
  Background,
  Background1,
  Background2,
  Background3,
  mouse,
}) {
  const classes = useStyles();

  var pageHeight = window.innerHeight;

  $(function () {
    let currentPosition = 0;
    let photo = $(".photo");
    let photoNums = photo.length;

    let speed = 3000;
    let timeout = 4000;
    $(".photo").eq(0).show();

    function move() {
      let nextPhoto = (currentPosition + 1) % photoNums;
      photo.eq(currentPosition).fadeOut(speed);
      photo.eq(nextPhoto).fadeIn(speed);
      currentPosition = nextPhoto;
    }
    setInterval(move, timeout);
  });

  const scroll = () => {
    window.scroll({ top: `${pageHeight}`, left: "0", behavior: "smooth" });
  };
  return (
    <>
      <div className={classes.mainBox}>
        <div id="slider">
          <div
            className="photos photo"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.30),rgba(0, 0, 0, 0.30)), url(${Background1})`,
            }}
          ></div>
          <div
            className="photo"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.30),rgba(0, 0, 0, 0.30)), url(${Background2})`,
            }}
          ></div>
          <div
            className="photo"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.70),rgba(0, 0, 0, 0.70)), url(${Background3})`,
            }}
          ></div>
        </div>

        <div className={classes.textBox}>
          <Fade bottom>
            <h1 className={classes.mainText}>SYSTEM LEVEL SOLUTIONS</h1>
          </Fade>

          <Fade bottom>
            <span className={classes.redLine}></span>
          </Fade>

          <h6 className={classes.subText}>
            <Fade bottom>
              Industry leaders in providing solutions catering to the domains of hardware design & prototyping, software development, and manufacturing.
            </Fade>
            <Button
              className={classes.btn}
              // style={style}
              href='https://semiconductor.slscorp.com/'
              target="_blank"
            >
              <span className={classes.mySpan}>
                <h6 className={classes.btnTitle}>
                  SLS Semiconductor: Empowering Innovations
                </h6>
              </span>
            </Button>
          </h6>
        </div>

        <div className={classes.scrollBox}>
          <Fade top>
            <Box className={classes.mouseScroll}>
              <img src={mouse} alt="Scroll image" onClick={scroll} />
            </Box>
          </Fade>
          <Fade top>
            <h6 className={classes.mouseScrollTxt}>Scroll</h6>
          </Fade>
        </div>
      </div>
    </>
  );
}
