import React from 'react'
import { Navbar, Footer } from '..'
import { Ubanner, Overview, Realtime } from '../../molecules'
import { Dnumbers } from './Dnumbers'
import { CSRcarousel } from './CSRcarousel'
import { Quality, QualityReverse, Offerings } from '../../molecules'
import { certification, csrcarousel } from '../../molecules/PortfolioData'
import { GridBlue } from '../../atoms'
import quality from '../../assets/policies/Quality_Policy.pdf'
import ems from '../../assets/policies/Environmental_Policy.pdf'
import ims from '../../assets/policies/Information_Security_Policy.pdf'
import pot from '../../assets/policies/Purchase_order_Terms.pdf'


export function AboutusTemplate() {
    return (
        <div>
            <Ubanner
                Background={"./images/about/About-us.png"}
                maintext="About us"
                subtext="Technology partner in your business growth journey"
            />

            <GridBlue>
                <Navbar />

                <Overview
                    title='About sls'
                    sub1='System Level Solutions is a turnkey product and service provider to the leading private and government
                        enterprises across America, Europe, and Asia. With a chief focus on business-boosting and technology-driven innovative solutions,
                        we act as technology partners in growth, providing bespoke solutions across a wide array of industries.'
                    sub2='We have the in-house capability to rapidly develop, prototype, provide proof of concept and mass production
                        services across hardware, software, firmware, and wireless technologies. Our motive is to work hand-in-hand with your business goals to
                        deliver market-ready products boasting class-apart design, innovative functionalities, excellent quality.'

                />

            </GridBlue>

            <Dnumbers />

            <CSRcarousel data={csrcarousel} />

            <Quality
                mainheading="quality & reliabilty"
                heading="Quality Policy"
                imageBG="./images/about/Quality-policy.png"
                subtitle="To maintain the quality of our products and services, we have set in place a Quality Policy that helps our teams 
                ensure top-notch quality and service delivery to our clients."
                redline="redline"
                download="download policy"
                tab_title={"Quality_Policy"}
                href={quality}
            />

            <QualityReverse
                heading="Environmental Policy"
                imageBG="./images/annie-spratt-hCb3lIB8L8E-unsplash.jpg"
                subtitle="We ensure our commitment towards the environment and mother earth through our Environment Policy. 
                The policy lays down certain protocols which are stringently followed to maintain environment-friendly operations."

                redline="redline"
                download="download policy"
                href={ems}
            />
            <Quality
                heading="Information Security Policy"
                imageBG="./images/about/sls_information_security_policy.jpg"
                subtitle="We have committed to implementing and maintaining an Information Security Management System,
                in accordance with the requirements of ISO 27001. This covers the security of processed and stored information concerning customers,
                financial advisers, and business partners associated with the provision of wealth management products and services."

                redline="redline"
                download="download policy"
                href={ims}
            />
            <QualityReverse
                heading="Purchase Order Terms"
                imageBG="./images/about/purchase_order_terms.jpg"
                subtitle="Ensure a smooth and efficient procurement process with our Purchase Order Terms. Our comprehensive terms establish clear expectations with suppliers on pricing, payment, delivery times, and quality standards, ensuring a transparent and reliable contract that protects business interests."

                redline="redline"
                download="download policy"
                href={pot}
            />

            <Realtime
                data={certification}
                title="Certifications and Policies"
            />

            <Footer />

        </div>
    )
}
