import React from 'react'
import {Footer } from './Footer'
import { Navbar} from './Navbar'
import { Ubanner, TwentyGBPS,} from '../../molecules'
import { makeStyles, Box, Container, IconButton, Toolbar, AppBar } from '@material-ui/core'
import Fade from 'react-reveal/Fade';
import { GridBlue } from '../../atoms'
import { Letstalk } from './Letstalk'
import { smartstreet } from '../../molecules/PortfolioData'

const useStyles = makeStyles((theme) => ({
    prContianer: {
        backgroundColor: 'white',             
        margin: '0px',
        padding: '0px',                
        boxSizing: 'border-box',                
    }
}))


export function TwentyGBPSTemplate() {
    const classes = useStyles()
    return (
        <>
            {/* <Ubanner
                Background="../images/testing/testing-banner.png"
                maintext="Title 20GBPS"
                subtext="Title 20 gb subtext"
            /> */}

            
            

           

            {/* <TwentyGBPS
                // mainheading="Hardware Offering"
                heading="NEMA-mounted Individual Street Light Controller"
                subtitle="Our Individual Street Light Controller helps transform regular street lights into smart lamps and offers real-time monitoring and control of individual street lights in your area.After the introduction, the body of the essay starts. The body paragraphs make the body of an essay. For a 500-word essay, particularly, there are usually 4-6 paragraphs.

                Each paragraph discusses a key element and provides the required evidence to give it a logical sense.
                
                Like the body paragraph of any other essay, the paragraphs of the 500-word essay start with a topic sentence. This sentence acts as an introduction to the paragraph.
                
                Each paragraph should end with a transition sentence to show a connection with the upcoming part.
                
                Make sure that such a body paragraph is meaningful yet in a comprehensive form.
                
                Here is a sample body paragraph to help you craft a winning 500-word essay
                
                Continuation of the same examples...
                
                The principal basis for restricting cell phone use in the classroom is that phones can act as a distraction. Not only do students and teachers become diverted, but this has an analogous impact on someone glancing at their device during a movie screening - even if it's silent, the illuminated display will still divert one's focus away from what matters most!
                
                When debating mobile phone regulations in the classroom, safety is typically brought to the forefront. Emergencies may happen at any time and students should have their phones with them for peace of mind. Parents' needs come into play too; if a student has children, they could need access to call someone during medical emergencies. Furthermore, if an individual is on standby for work-related matters then having a cell phone accessible would be useful as well - there are endless plausible scenarios that make it difficult not to provide exceptions from these rules!"
                redline="redline"
            /> */}
            
            

            {/* <GridBlue> */}
            <Box className={classes.prContianer} >
                <Navbar />
                <Letstalk />
            </Box>
            {/* </GridBlue> */}

            <Footer  />
        </>
    )
}
