import React from 'react'
import { makeStyles, Container, Box } from '@material-ui/core'
import { PositionImage, Paragraphs, MainContainer, MapIcon1 } from "../atoms"
import Bounce from 'react-reveal/Bounce';

const useStyles = makeStyles((theme) => ({
    mainHeads: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            lineHeight: '1.2',
        },
    },
    qualityContainer: {
        height: 'fit-content',
        margin: '10% auto',
        width: '100%'
    },
    BoxLeft: {
        width: '50%',
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    centerBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    bigHead: {
        color: 'black',
        textAlign: 'center',
        marginBottom: '10%',
    },
    bigSubHead: {
        width: '60%',
        color: 'black',
        textAlign: 'center',
        margin: '0% auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    },
    gridContainer: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: '10% 0%'
        }
    },
    text: {
        color: 'black',
        width: '100%', // Ensure text takes full width
        textAlign: 'center', // Center align text
    }
}));

export function TwentyGBPS({
    data1,
    mainheading,
    mainsubheading,
    imageBG,
    e,
    heading,
    subhead,
    subtitle,
    redline,
    style,
}) {
    const classes = useStyles()
    return (
        <MainContainer>
            <Container fixed className={classes.qualityContainer}>
                {/* {
                    mainheading && (
                        <Box className={classes.mainHeads}>
                            <Bounce bottom>
                                <h2 className={classes.bigHead}>
                                    {mainheading}
                                    <h6 className={classes.bigSubHead}>{mainsubheading}</h6>
                                </h2>
                            </Bounce>
                        </Box>
                    )
                } */}

                <Box className={classes.gridContainer} style={style}>
                    <Box className={classes.BoxLeft}>
                        <Bounce left>
                            <PositionImage imageBG={imageBG} />
                        </Bounce>
                    </Box>

                    <Box className={classes.centerBox}>
                        <Bounce right>
                            <Paragraphs
                                e={e}
                                title={heading}
                                subhead={subhead}
                                subtitle={subtitle}
                                redline={redline}
                                
                            />

                           
                        </Bounce>
                    </Box>
                </Box>
            </Container>
        </MainContainer>
    )
}